import {mapRunningTimes} from '@hconnect/common/mappers'
import {EquipmentRunningTimes, EquipmentRunningTimesDto} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {TimeRange} from '../../types/timeAndDate.types'
import {useConfig} from '../useConfig'

import {useApi} from './useApi'

const QueryKey = 'running-times'

export const useRunningTimes = (
  timeRange: TimeRange,
  options?: UseQueryOptions<EquipmentRunningTimes[], AxiosError>
) => {
  const {axiosInstance} = useApi()
  const {plantId, timezone} = useConfig()

  return useQuery<EquipmentRunningTimes[], AxiosError>(
    [QueryKey, timeRange, plantId, timezone],
    async () => {
      const response = await axiosInstance.get<EquipmentRunningTimesDto[]>(
        `/shifthandover/${plantId}/running-times`,
        {params: {from: timeRange.startDate, to: timeRange.endDate}}
      )
      return (response.data || []).map((data) => ({
        ...data,
        runningTimes: mapRunningTimes(data.runningTimes, timezone)
      }))
    },
    options
  )
}
