import {DateRange, FilterTimeRange} from '@hconnect/common/types'
import moment from 'moment'
import {useEffect, useMemo, useState} from 'react'

import {
  FilterOptions,
  getQuickSelectFilter,
  QUICK_SELECT_SLOTS,
  QuickSelectSlots
} from '../common/utils/filterParameter'
import {isSingleShiftRange} from '../common/utils/shift'
import {useQueryParameterForFilterOptions} from '../hooks/useFilterQueryParams'
import {TimeRange} from '../types/timeAndDate.types'
import {toTimeRange} from '../utils/timeRangeHelper'

import {generateTimeRange} from './api/useEventsSearch'
import {useConfig} from './useConfig'

export const useFilterDatePicker = () => {
  const {defaultTimeRange, shifts, plantNow, timezone} = useConfig()
  const filterOptions: FilterOptions = useQueryParameterForFilterOptions()
  const quickSelectSlot: QuickSelectSlots =
    (QUICK_SELECT_SLOTS.includes(filterOptions.timeRange as QuickSelectSlots) &&
      (filterOptions.timeRange as QuickSelectSlots)) ||
    defaultTimeRange
  const [timeRange, setTimeRange] = useState<TimeRange>(
    toTimeRange(getQuickSelectFilter(quickSelectSlot, shifts, plantNow()))
  )
  const [isSingleShift, setShiftRange] = useState<boolean>(
    isSingleShiftRange(
      moment.utc(timeRange.startDate).tz(timezone),
      moment.utc(timeRange.endDate).tz(timezone),
      shifts
    )
  )
  const dateRange = useMemo<DateRange>(
    () => ({
      startDate: moment.utc(timeRange.startDate).tz(timezone),
      endDate: moment.utc(timeRange.endDate).tz(timezone)
    }),
    [timeRange.endDate, timeRange.startDate, timezone]
  )

  const getDateRange = (): DateRange => {
    const [startDate, endDate] = getQuickSelectFilter(defaultTimeRange, shifts, plantNow())
    return {startDate, endDate}
  }

  useEffect(() => {
    const isSingleShift = isSingleShiftRange(
      moment.utc(timeRange.startDate).tz(timezone),
      moment.utc(timeRange.endDate).tz(timezone),
      shifts
    )
    setShiftRange(isSingleShift)
  }, [timeRange, shifts, timezone])

  useEffect(() => {
    const filterRange: FilterTimeRange = generateTimeRange(filterOptions, shifts, plantNow)
    if (filterRange.startDate && filterRange.endDate) {
      const range: TimeRange = {
        startDate: filterRange.startDate,
        endDate: filterRange.endDate
      }
      setTimeRange(range)
    }
  }, [filterOptions, timezone, shifts, plantNow])

  return {
    timeRange,
    dateRange,
    isSingleShift,
    getDateRange,
    quickSelectSlot
  }
}
