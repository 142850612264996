import {ViewMode} from '@hconnect/common/components/eventsList/types'
import {Stoppage, StoppageDto} from '@hconnect/common/types'
import {SetURLSearchParams, useSearchParams} from 'react-router-dom'

import {mapPartialStoppage} from '../mappers'
import {ShiftEvent} from '../types/shiftHandover.types'

import {useConfig} from './useConfig'

function urlParamsToViewMode(params: URLSearchParams, timezone: string): ViewMode<ShiftEvent> {
  const eventId = params.has('eventId') ? params.get('eventId') : undefined
  const mode = params.has('mode') ? params.get('mode') : undefined

  let initialEventCreateData: Partial<Stoppage> | undefined
  try {
    const initialEventCreateDataParam = params.has('initialData')
      ? decodeURIComponent(params.get('initialData') as string)
      : undefined
    const initialData: Partial<StoppageDto> =
      initialEventCreateDataParam && JSON.parse(initialEventCreateDataParam)
    // TODO: check if this is used only for stoppages
    initialEventCreateData = initialData && mapPartialStoppage(initialData, timezone)
  } catch (e) {
    console.log('Error parsing initial stoppage data', e)
  }

  switch (mode) {
    case undefined:
      if (eventId) return {mode: 'detailsMode', itemId: eventId} as ViewMode<ShiftEvent>
      else return {mode: 'tableOnlyMode'} as ViewMode<ShiftEvent>

    case 'editMode':
    case 'detailsMode':
      if (!eventId) throw new Error('Event Id must be given with mode')
      return {mode, itemId: eventId}
    case 'createMode':
      return {mode, initialData: initialEventCreateData}
    default:
      throw new Error('Unknown mode given in URL')
  }
}

export function viewModeToUrlParams(viewMode: ViewMode<ShiftEvent>) {
  const params = new URLSearchParams()

  switch (viewMode.mode) {
    case 'tableOnlyMode':
      return params
    case 'editMode':
    case 'detailsMode':
      params.append('mode', viewMode.mode)
      params.append('eventId', viewMode.itemId.toString())
      return params
    case 'createMode':
      params.append('mode', 'createMode')
      // add initial data to params
      params.append('initialData', encodeURIComponent(JSON.stringify(viewMode.initialData)))
      return params
    default:
      throw new Error('Unknown mode')
  }
}

export const useQueryParameterForViewMode = (): ViewMode<ShiftEvent> => {
  const [params]: [URLSearchParams, SetURLSearchParams] = useSearchParams()
  const {timezone} = useConfig()
  return urlParamsToViewMode(params, timezone)
}
