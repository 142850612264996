import {AxiosError} from 'axios'
import {useQuery, UseQueryResult} from 'react-query'

import {WorkOrder} from '../../types/plantConfig.types'
import {useConfig} from '../useConfig'

import {useApi} from './useApi'

const QueryKey = 'work-orders'

export const useSapWorkOrders = (
  notificationId: string
): UseQueryResult<WorkOrder[], AxiosError> => {
  const {axiosInstance} = useApi()
  const {plantId} = useConfig()

  return useQuery([QueryKey, plantId, notificationId], async () => {
    const {data} = await axiosInstance.get<WorkOrder[]>(
      `/plantconfig/${plantId}/work-orders?pmNotificationId=${notificationId}`
    )
    return data
  })
}
