import type {User, RoleType} from '@hconnect/apiclient'
import {User as UserData} from '@hconnect/common/types'

import {mockQueryReturn} from '../helpers'

export type Persona = Pick<User, 'country' | 'defaultLocale' | 'id' | 'eMail'> & {
  name: string
  hasRoles: RoleType[]
}

export const shiftLeader: Persona = {
  country: 'DE',
  defaultLocale: 'de-DE',
  eMail: 'william.shiftleader.sample@heidelbergcement.com',
  hasRoles: ['SHIFT_LEADER', 'SAP_USER'],
  name: 'William Shiftleader',
  id: '00000000-0000-0000-0000-000000000003'
}

export const shiftWorkerJohnny: Persona = {
  country: 'DE',
  defaultLocale: 'de-DE',
  eMail: 'johnny.shiftworker.sample@heidelbergcement.com',
  hasRoles: ['SHIFT_WORKER'],
  name: 'Johnny Shiftworker',
  id: '00000000-0000-0000-0000-000000000004'
}

export const shiftWorkerMarry: Persona = {
  country: 'DE',
  defaultLocale: 'de-DE',
  eMail: 'marry.shiftworker.sample@heidelbergcement.com',
  hasRoles: ['SHIFT_WORKER'],
  name: 'Marry Shiftworker',
  id: '00000000-0000-0000-0000-000000000005'
}

export const readOnlyUserJames: Persona = {
  country: 'DE',
  defaultLocale: 'de-DE',
  eMail: 'james.readOnly.sample@heidelbergcement.com',
  hasRoles: ['SHIFT_REPORT_READ_ONLY'],
  name: 'James readOnly',
  id: '00000000-0000-0000-0000-000000000006'
}

export const unAuthorizedUser: Persona = {
  country: 'DE',
  defaultLocale: 'de-DE',
  eMail: 'alfred.unauthorized.sample@heidelbergcement.com',
  hasRoles: ['SALES_AGENT'],
  name: 'Alfred Unauthorized',
  id: '00000000-0000-0000-0000-000000000000'
}

export const reliabilityEngineer: Persona = {
  country: 'DE',
  defaultLocale: 'de-DE',
  eMail: 'engineer.reliability.sample@heidelbergcement.com',
  hasRoles: ['RELIABILITY_ENGINEER'],
  name: 'Reliability Engineer',
  id: '00000000-0000-0000-0000-100000000001'
}

export const reliabilityContributor: Persona = {
  country: 'DE',
  defaultLocale: 'de-DE',
  eMail: 'contributor.reliability.sample@heidelbergcement.com',
  hasRoles: ['RELIABILITY_CONTRIBUTOR'],
  name: 'Reliability Contributor',
  id: '00000000-0000-0000-0000-100000000002'
}

export const personas = {
  shiftLeader,
  shiftWorkerJohnny,
  shiftWorkerMarry,
  readOnlyUserJames,
  unAuthorizedUser,
  reliabilityEngineer,
  reliabilityContributor
}

export const usersData = mockQueryReturn<UserData[]>(
  Object.values(personas).map(({name, id}) => ({name, id}))
)
