import {NotificationProvider} from '@hconnect/uikit'
import {hpTheme} from '@hconnect/uikit/src/lib2'
import {ThemeProvider} from '@mui/material'
import React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'

export const CockpitTestWrapper = ({children}: {children: React.ReactNode}) => (
  <ThemeProvider theme={hpTheme}>
    <NotificationProvider>
      <Router>{children}</Router>
    </NotificationProvider>
  </ThemeProvider>
)
