import {ParticularShift} from '@hconnect/common/types/shift.types'
import {Dates, formatDateLocale} from '@hconnect/uikit'
import {
  Calendar,
  DatePickerTextField,
  PickerForm,
  TabBar
} from '@hconnect/uikit/src/lib2/components/momentDatePicker'
import {Button, Checkbox, Popover, Box, FormControlLabel} from '@mui/material'
import {Moment} from 'moment-timezone'
import React, {useCallback, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {QuickSelectSlots} from '../../common/utils/filterParameter'
import {useConfig} from '../../hooks/useConfig'
import {getResponsivePopoverStyle} from '../../styles/common'

import {QuickSelect} from './QuickSelect'
import {handleDateChange, ShiftDropdown, getShiftRange, momentShiftPickLabel} from './ShiftDropdown'

type DateRange = [Moment | null, Moment | null]

interface Props {
  className?: string
  date?: Moment | null
  dateRange?: DateRange
  quickSelectId?: string
  isOutsideRange?: (arg: Moment) => boolean
  label?: React.ReactNode
  onChange: (value) => void
}

enum TYPES {
  QUICK_SELECT = 'quickSelect',
  DATE = 'date',
  DATE_RANGE = 'dateRange'
}

const getSelectionType = (quickSelectId, date, dateRange) => {
  if (quickSelectId) return TYPES.QUICK_SELECT
  if (date) return TYPES.DATE
  if (dateRange) return TYPES.DATE_RANGE
  throw new Error('no value selected in date picker')
}

const DatePicker: React.FC<Props> = (props) => {
  const config = useConfig()

  const {shifts, timezone} = config

  const {quickSelectId, date, dateRange = [], isOutsideRange, onChange, className} = props
  const {t, i18n} = useTranslation()
  const {language} = i18n
  const isSingleDate = date !== undefined
  const [startDate, endDate] = dateRange
  const [selectedStartDate, setSelectedStartDate] = useState<Moment | null>(
    date || startDate || null
  )
  const [selectedEndDate, setSelectedEndDate] = useState<Moment | null | undefined>(endDate)
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [showCalendar, setShowCalendar] = useState(!quickSelectId)
  const [isRangeSelect, setShowRangeSelect] = useState(!isSingleDate)
  const [selectionType, setType] = useState(getSelectionType(quickSelectId, date, dateRange))
  const noDateText = t('datePicker.chooseDate')

  const selectionText = useMemo(() => {
    if (selectionType === TYPES.QUICK_SELECT) {
      return t(`shiftEvent.quickSelection.${quickSelectId}`)
    }
    if (selectionType === TYPES.DATE || !selectedEndDate) {
      return momentShiftPickLabel(selectedStartDate as Moment, {shifts})
    }
    if (selectionType === TYPES.DATE_RANGE) {
      return `${formatDateLocale(selectedStartDate as Moment, language)} - ${formatDateLocale(
        selectedEndDate,
        language
      )}`
    }
    return noDateText
  }, [
    selectionType,
    selectedEndDate,
    noDateText,
    t,
    quickSelectId,
    selectedStartDate,
    shifts,
    language
  ])

  const close = useCallback(() => setOpen(false), [setOpen])

  const handleDateRangeChange = (dates: Dates) => {
    setSelectedStartDate(dates.startDate || null)
    setSelectedEndDate(dates.endDate || null)

    if (dates.endDate) {
      onChange(dates)
      setType(TYPES.DATE_RANGE)
    }
  }

  const handleSingleDateChange = (date: Moment | null) => {
    if (!date) return
    const updatedDate = getShiftRange(handleDateChange(date, {shifts}))
    setSelectedStartDate(updatedDate.startDate)
    setSelectedEndDate(updatedDate.startDate)
    onChange(updatedDate)
    setType(TYPES.DATE)
  }

  const calendarProps = {
    timezone,
    isOutsideRange,
    startDate: selectedStartDate,
    ...(isRangeSelect
      ? {endDate: selectedEndDate, onRangeChange: handleDateRangeChange}
      : {onDateChange: handleSingleDateChange})
  }

  return (
    <Box
      sx={{
        padding: 0,
        display: 'inline-block',
        position: 'relative',
        zIndex: 999,
        width: {
          xs: '100%',
          md: 'inherit'
        }
      }}
      className={className}
    >
      <DatePickerTextField
        buttonRef={anchorRef}
        focus={open}
        setFocus={setOpen}
        text={selectionText}
        label={t('datePicker.label')}
      />

      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          sx: getResponsivePopoverStyle,
          id: 'simple-date-picker-popover-paper'
        }}
        data-test-id="simple-date-picker-popover"
      >
        <Box
          sx={{
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 356
          }}
        >
          <Box pb={1}>
            <TabBar setShowCalendar={setShowCalendar} showCalendar={showCalendar} />
          </Box>

          {showCalendar ? (
            <>
              <Calendar {...calendarProps} />
              <Box
                sx={(theme) => ({
                  color: theme.palette.text.primary,
                  width: '100%'
                })}
              >
                <FormControlLabel
                  sx={{mb: 1}}
                  control={
                    <Checkbox
                      data-test-id="range-select"
                      checked={isRangeSelect}
                      onChange={() => setShowRangeSelect((prev) => !prev)}
                    />
                  }
                  label={t('datePicker.selectRange')}
                />
                {isRangeSelect ? (
                  <PickerForm
                    applyDateRange={(value: Dates) => {
                      handleDateRangeChange(value)
                      close()
                    }}
                    isOutsideRange={isOutsideRange}
                    isSingleDate={!isRangeSelect}
                    endDate={selectedEndDate}
                    startDate={selectedStartDate}
                    timeZone={timezone}
                  />
                ) : (
                  <>
                    <ShiftDropdown
                      date={selectedStartDate}
                      onChange={(particularShift: ParticularShift) => {
                        const range = getShiftRange(particularShift)
                        onChange(range)
                        setSelectedStartDate(range.startDate)
                        setType(TYPES.DATE)
                      }}
                      label={t('datePicker.label')}
                    />
                    <Box display={'flex'} justifyContent={'center'} pt={2}>
                      <Button
                        variant={'contained'}
                        onClick={() => setOpen(false)}
                        data-test-id="single-day-apply"
                      >
                        {t('datePicker.apply')}
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </>
          ) : (
            <QuickSelect
              onChange={(val: QuickSelectSlots) => {
                onChange(val)
                setType(TYPES.QUICK_SELECT)
                close()
              }}
              quickSelectId={quickSelectId}
            />
          )}
        </Box>
      </Popover>
    </Box>
  )
}

export {DatePicker}
