import {AxiosError} from 'axios'
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from 'react-query'

import {mapComment} from '../../mappers'
import {Comment, CommentDto} from '../../types/shiftHandover.types'
import {useConfig} from '../useConfig'

import {useApi} from './useApi'
import {CreateCommentParameter} from './useShiftComments'

export const QueryKey = 'events-comments'

export const useComments = (eventId: string, options?: UseQueryOptions<Comment[], AxiosError>) => {
  const {axiosInstance} = useApi()
  const {plantId, timezone} = useConfig()
  const path = `/shifthandover/${plantId}/events/${eventId}/comments`

  return useQuery<Comment[], AxiosError>(
    [QueryKey, eventId, plantId, timezone],
    async () => {
      const response = await axiosInstance.get<CommentDto[]>(path)
      return response.data.map((comment) => mapComment(comment, timezone))
    },
    options
  )
}

export const useCreateComment = (
  options?: UseMutationOptions<Comment, AxiosError, CreateCommentParameter>
) => {
  const {axiosInstance} = useApi()
  const queryClient = useQueryClient()
  const {plantId, timezone} = useConfig()

  return useMutation<Comment, AxiosError, CreateCommentParameter>(
    async ({eventId, description}) => {
      const path = `/shifthandover/${plantId}/events/${eventId}/comments`

      const response = await axiosInstance.post<CommentDto>(path, {description})
      return mapComment(response.data, timezone)
    },
    {
      ...options,
      // make sure to invalidate the Queries after the regular onSuccess process is done
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(QueryKey)
        await options?.onSuccess?.(data, variables, context)
      }
    }
  )
}
