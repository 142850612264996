import {formatDateLocale} from '@hconnect/uikit'
import {
  formatTimeLocale,
  QuickSelection
} from '@hconnect/uikit/src/lib2/components/momentDatePicker'
import {QuickSelectionItem} from '@hconnect/uikit/src/lib2/components/momentDatePicker/types'
import {TFunction} from 'i18next'
import moment, {Moment} from 'moment-timezone'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {
  getQuickSelectFilter,
  QuickSelectSlots,
  QUICK_SELECT_SLOTS
} from '../../common/utils/filterParameter'
import {roundUpToTheNearestMinute} from '../../common/utils/shift'
import {useConfig} from '../../hooks/useConfig'

const getDateLabel = ({
  t,
  shiftStart,
  currentDate,
  language
}: {
  t: TFunction
  shiftStart: Moment
  currentDate: Moment
  language: string
}) => ({
  currentShift: () => t('shiftEvent.quickSelection.today'),
  pastShift: () => {
    const isToday = shiftStart.isSame(currentDate, 'date')
    return isToday ? t('shiftEvent.quickSelection.today') : t('shiftEvent.quickSelection.yesterday')
  },
  last24hours: () => t('shiftEvent.quickSelection.last24hours'),
  yesterday: () => t('shiftEvent.quickSelection.yesterday'),
  lastWeekend: () => formatDateLocale(shiftStart, language),
  last3days: () => formatDateLocale(shiftStart, language),
  last7days: () => formatDateLocale(shiftStart, language),
  last30days: () => formatDateLocale(shiftStart, language),
  next30days: () => formatDateLocale(shiftStart, language)
})

type Props = {
  quickSelectId?: string
  onChange: (item: QuickSelectSlots) => void
}

export const QuickSelect: React.FC<Props> = ({quickSelectId, onChange}) => {
  const config = useConfig()

  const {shifts, timezone, plantNow} = config
  const {t, i18n} = useTranslation()
  const state = useMemo(() => {
    const options: QuickSelectionItem[] = QUICK_SELECT_SLOTS.reduce(
      (acc: QuickSelectionItem[], curr: QuickSelectSlots) => {
        const [start, end] = getQuickSelectFilter(curr, shifts, plantNow())
        acc.push({
          id: curr,
          title: t(`shiftEvent.quickSelection.${curr}`),
          dates: [start, roundUpToTheNearestMinute(end)]
        })
        return acc
      },
      []
    )

    const selectedItem = options?.filter((item) => {
      return item.id === quickSelectId
    })[0]

    const customTemplate = (option: QuickSelectionItem) => {
      const {id, dates} = option
      const [start, end] = dates
      const currentDate = moment.tz(timezone)

      const dateLabel = id
        ? getDateLabel({
            t,
            shiftStart: start,
            currentDate,
            language: i18n.language
          })[id]()
        : t(`shiftEvent.quickSelection.${id}`)
      return `${dateLabel} , ${formatTimeLocale(start)} - ${end && formatTimeLocale(end)}`
    }
    return {options, selectedItem, customTemplate}
  }, [i18n.language, quickSelectId, shifts, t, timezone, plantNow])

  const {options, selectedItem, customTemplate} = state

  return (
    <QuickSelection
      handleItemClick={(_, quickSelectItem) => {
        onChange(quickSelectItem?.id as QuickSelectSlots)
      }}
      selectedItem={selectedItem}
      dateCustomTemplate={customTemplate}
      items={options}
      timezone={timezone}
    />
  )
}
