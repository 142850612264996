import {useNotification} from '@hconnect/uikit'
import {AxiosError} from 'axios'
import {useTranslation} from 'react-i18next'
import {useMutation, useQueryClient, UseMutationOptions} from 'react-query'

import {mapComment} from '../../mappers'
import {Comment, CommentDto} from '../../types/shiftHandover.types'
import {useConfig} from '../useConfig'

import {useApi} from './useApi'

export const QueryKey = 'events-comments'

export type EditCommentParameter = {
  commentId: string
  description: string
}

export type DeleteCommentParameter = {
  commentId: string
}

export const useEditComment = (
  isShiftComment = false,
  options?: UseMutationOptions<Comment, AxiosError, EditCommentParameter>
) => {
  const {axiosInstance} = useApi()
  const queryClient = useQueryClient()
  const {plantId, timezone} = useConfig()
  const {t} = useTranslation()
  const {notify} = useNotification()

  return useMutation<Comment, AxiosError, EditCommentParameter>(
    async ({commentId, description}) => {
      const path = isShiftComment
        ? `/shifthandover/${plantId}/shiftcomments/${commentId}`
        : `/shifthandover/${plantId}/comments/${commentId}`
      const payload = {
        description
      }

      const mutation = await axiosInstance.put<CommentDto>(path, payload)
      return mapComment(mutation.data, timezone)
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        notify('success', t('comments.action.editSuccess'))
        await queryClient.invalidateQueries(QueryKey)
        await options?.onSuccess?.(data, variables, context)
      },
      onError: () => {
        notify('error', t('comments.action.editFailed'))
      }
    }
  )
}

export const useDeleteComment = (
  isShiftComment = false,
  options?: UseMutationOptions<Comment, AxiosError, DeleteCommentParameter>
) => {
  const {axiosInstance} = useApi()
  const queryClient = useQueryClient()
  const {plantId, timezone} = useConfig()
  const {t} = useTranslation()
  const {notify} = useNotification()

  return useMutation<Comment, AxiosError, DeleteCommentParameter>(
    async ({commentId}) => {
      const path = isShiftComment
        ? `/shifthandover/${plantId}/shiftcomments/${commentId}`
        : `/shifthandover/${plantId}/comments/${commentId}`

      const mutation = await axiosInstance.delete<CommentDto>(path)
      return mapComment(mutation.data, timezone)
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        notify('success', t('comments.action.deleteSuccess'))
        await queryClient.invalidateQueries(QueryKey)
        await options?.onSuccess?.(data, variables, context)
      }
    }
  )
}
