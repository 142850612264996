import {SplitStoppageDialog} from '@hconnect/common/components/splitStoppage'
import {MIN_SPLIT_STOPPAGE_DURATION} from '@hconnect/common/consts'
import {StoppageDto, WorkOrderTarget} from '@hconnect/common/types'
import ContentCutIcon from '@mui/icons-material/ContentCut'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import EditIcon from '@mui/icons-material/Edit'
import FileUpload from '@mui/icons-material/FileUpload'
import moment from 'moment'
import React, {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {hasMaintenanceNotificationCreationTimedOut} from '../../common/utils/eventDetailsHelper'
import {isParameterChange, isTask, isStoppage} from '../../common/utils/eventType'
import {useInforWorkOrderRequest} from '../../hooks/api/workOrders/useInforWorkOrderRequest'
import {useFeatureFlag} from '../../hooks/useFeatureFlag'
import {useGlobalContext} from '../../hooks/useGlobalContext'
import {useMaintenanceNotification} from '../../hooks/useMaintenanceNotification'
import {useHasPermission} from '../../hooks/usePermission'
import {mapPartialStoppage, mapShiftEventDto} from '../../mappers'
import {SapStatusCode} from '../../types/maintenanceNotification.types'
import {ShiftEvent} from '../../types/shiftHandover.types'

import {ActionListItem, ActionList} from './ActionList'
import {CreateInforMaintenanceNotification} from './CreateInforMaintenanceNotification'
import {CreateSapMaintenanceNotification} from './CreateSapMaintenanceNotification'
import {EventDelete} from './EventDelete'

type EventActionListProps = {
  item: ShiftEvent
  doEdit: (item: ShiftEvent) => void
  doStoppageSplit: (initialStoppageData: Partial<ShiftEvent>) => void
  disableSap?: boolean
  doClose: () => void
  onlyDeleteSeries?: boolean
  onSapNotificationCreated: () => void
  maintenanceNotificationFailed: boolean
  setMaintenanceNotificationFailed: (state: boolean) => void
}

export const EventActionList: React.FC<EventActionListProps> = (props) => {
  const {
    item,
    doEdit,
    disableSap,
    doClose,
    onlyDeleteSeries,
    onSapNotificationCreated,
    maintenanceNotificationFailed,
    setMaintenanceNotificationFailed
  } = props

  const [showDeleteDialog, setDeleteDialog] = useState<boolean>(false)
  const [showSapNotificationDialog, setSapNotificationDialog] = useState<boolean>(false)
  const [showInforNotificationDialog, setInforNotificationDialog] = useState<boolean>(false)
  const [showSplitStoppageDialog, setSplitStoppageDialog] = useState<boolean>(false)

  const {t} = useTranslation()
  const hasPermission = useHasPermission()
  const {user, config} = useGlobalContext()

  const isSapMaintenanceEnabled = useFeatureFlag('sapMaintenanceNotification')
  const isInforMaintenanceEnabled = useFeatureFlag('inforMaintenanceNotification')
  const isSplitStoppageEnabled = useFeatureFlag('enableSplitStoppage')
  const isMaintenanceAllowed = hasPermission('CREATE_SAP_REQUESTS')
  const {workOrderTarget, timezone, pxTrendAvailable} = config

  const isDeleteAllowed = isTask(item)
    ? hasPermission('DELETE_PLANT_TASKS')
    : hasPermission('DELETE_PLANT_EVENTS')

  const createMaintenanceNotification = useMaintenanceNotification()
  const createInforMaintenanceNotification = useInforWorkOrderRequest()

  const disableEdit = useMemo(() => {
    const isOwner = item.createdBy ? item.createdBy.id === user.userId : true
    const isApprover =
      isParameterChange(item) &&
      !!item.parameterChange?.approvers?.find(({id}) => id === user.userId)

    return (
      isParameterChange(item) && item.parameterChange?.needsApproval && !(isOwner || isApprover)
    )
  }, [item, user.userId])

  // eslint-disable-next-line complexity
  const actionListItems: ActionListItem[] = useMemo(() => {
    const items: ActionListItem[] = []

    if (!disableEdit) {
      items.push({
        label: t('shiftEvent.action.edit'),
        startIcon: <EditIcon />,
        onClick: () => doEdit(item),
        dataTestId: 'event-edit-button'
      })
    }

    if (isStoppage(item) && isSplitStoppageEnabled) {
      const duration = moment
        .utc(item.stoppageEnd)
        .tz(timezone)
        .diff(moment.utc(item.stoppageStart).tz(timezone), 'minutes')
      if (duration > MIN_SPLIT_STOPPAGE_DURATION) {
        items.push({
          label: t('shiftEvent.action.split'),
          startIcon: <ContentCutIcon />,
          onClick: () => setSplitStoppageDialog(true),
          dataTestId: 'stoppage-split-button'
        })
      }
    }

    if (isDeleteAllowed) {
      items.push({
        label: t('shiftEvent.action.delete'),
        startIcon: <DeleteIcon />,
        onClick: () => setDeleteDialog(true),
        dataTestId: 'event-delete-button'
      })
    }

    const isSapInSubmitState = item?.sapStatusCode === SapStatusCode.SUBMITTED
    const isAlreadySapConverted = item?.sapStatusCode && !isSapInSubmitState
    if (
      !disableSap &&
      !isAlreadySapConverted &&
      isSapMaintenanceEnabled &&
      isMaintenanceAllowed &&
      workOrderTarget === WorkOrderTarget.Sap
    ) {
      items.push({
        label: t('sapPm.action.createMaintenanceNotification'),
        startIcon: <FileUpload />,
        onClick: () => setSapNotificationDialog(true),
        dataTestId: 'sap-pm-dialog-mn',
        disabled:
          maintenanceNotificationFailed ||
          hasMaintenanceNotificationCreationTimedOut(item, moment.tz(timezone)),
        isLoading: createMaintenanceNotification.isLoading || isSapInSubmitState
      })
    }

    const isAlreadyInforConverted = !!item?.inforWorkOrder?.requestId
    if (
      isInforMaintenanceEnabled &&
      isMaintenanceAllowed &&
      !isAlreadyInforConverted &&
      workOrderTarget === WorkOrderTarget.Infor
    ) {
      items.push({
        label: t('sapPm.action.createMaintenanceNotification'),
        startIcon: <FileUpload />,
        onClick: () => setInforNotificationDialog(true),
        dataTestId: 'infor-dialog-mn',
        disabled: maintenanceNotificationFailed,
        isLoading: createInforMaintenanceNotification.isLoading
      })
    }

    return items
  }, [
    disableEdit,
    isDeleteAllowed,
    item,
    disableSap,
    isSapMaintenanceEnabled,
    isMaintenanceAllowed,
    workOrderTarget,
    isInforMaintenanceEnabled,
    t,
    doEdit,
    maintenanceNotificationFailed,
    timezone,
    createMaintenanceNotification.isLoading,
    createInforMaintenanceNotification.isLoading,
    isSplitStoppageEnabled
  ])

  return (
    <>
      <ActionList listItems={actionListItems} />
      <EventDelete
        showDialog={showDeleteDialog}
        setDialogOpen={setDeleteDialog}
        onlyDeleteSeries={onlyDeleteSeries}
        event={item}
        onDelete={() => doClose()}
        plantId={config.plantId}
      />
      {showSapNotificationDialog && (
        <CreateSapMaintenanceNotification
          event={item}
          createNotification={createMaintenanceNotification}
          showDialog={showSapNotificationDialog}
          setDialog={setSapNotificationDialog}
          onNotificationCreated={onSapNotificationCreated}
          onError={() => setMaintenanceNotificationFailed(true)}
        />
      )}
      {showInforNotificationDialog && (
        <CreateInforMaintenanceNotification
          event={item}
          createNotification={createInforMaintenanceNotification}
          showDialog={showInforNotificationDialog}
          setDialog={setInforNotificationDialog}
          onNotificationCreated={onSapNotificationCreated}
          onError={() => setMaintenanceNotificationFailed(true)}
        />
      )}
      {isSplitStoppageEnabled && showSplitStoppageDialog && (
        <SplitStoppageDialog
          timezone={timezone}
          open={showSplitStoppageDialog}
          onClose={() => setSplitStoppageDialog(false)}
          stoppage={mapShiftEventDto(item) as StoppageDto}
          onSplitSubmit={(stoppage) =>
            props.doStoppageSplit(mapPartialStoppage(stoppage, timezone))
          }
          pxTrendAvailable={pxTrendAvailable}
        />
      )}
    </>
  )
}
