import {FilterTimeRange} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {omit} from 'lodash'
import moment from 'moment-timezone'
import {useQuery, UseQueryOptions} from 'react-query'

import {WorkOrdersFilterOptions} from '../../../common/utils/filterParameter'
import {
  WorkOrder,
  WorkOrderDto,
  WorkOrderPagedContainer,
  WorkOrdersPayload
} from '../../../types/workOrders.types'
import {preparePayload} from '../../../utils'
import {useConfig} from '../../useConfig'
import {useApi} from '../useApi'
import {generateTimeRange} from '../useEventsSearch'

const WorkOrdersQueryKey = 'work-orders'
const WorkOrderQueryKey = 'work-order'

export const useWorkOrders = (
  {input, offset, limit}: WorkOrdersPayload<WorkOrdersFilterOptions>,
  options?: UseQueryOptions<WorkOrderPagedContainer, AxiosError>
) => {
  const {axiosInstance} = useApi()
  const {plantNow, shifts, plantId} = useConfig()
  const path = `/work-orders/${plantId}/work-orders`

  return useQuery<WorkOrderPagedContainer, AxiosError>(
    [WorkOrdersQueryKey, path, shifts, input, offset, limit],
    async () => {
      const timeRange: FilterTimeRange = generateTimeRange(input, shifts, plantNow)
      const filters = {
        ...omit(input, ['timeRange', 'workOrderStatus', 'sortWorkOrderBy', 'woSortDir']),
        statuses: input.workOrderStatus,
        sortBy: input.sortWorkOrderBy,
        sortingDirection: input.woSortDir
      }
      const response = await axiosInstance.get<WorkOrderPagedContainer>(path, {
        params: {
          from: timeRange.startDate,
          to: timeRange.endDate,
          offset,
          limit,
          ...preparePayload<WorkOrdersFilterOptions>(filters)
        }
      })
      return response.data
    },
    options
  )
}

export const useWorkOrder = (
  workOrderId?: string,
  options?: UseQueryOptions<WorkOrder | undefined, AxiosError>
) => {
  const {axiosInstance} = useApi()
  const {plantId, timezone} = useConfig()
  const path = `/work-orders/${plantId}/work-orders/${workOrderId}`

  return useQuery<WorkOrder | undefined, AxiosError>(
    [WorkOrderQueryKey, path],
    async () => {
      if (!workOrderId) return undefined
      const response = await axiosInstance.get<WorkOrderDto>(path)
      return {
        ...response.data,
        createdAt: moment.utc(response.data.createdAt).tz(timezone),
        startDate: moment.utc(response.data.startDate).tz(timezone),
        endDate: moment.utc(response.data.endDate).tz(timezone)
      }
    },
    options
  )
}
